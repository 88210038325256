<template>
  <!-- 在地图上选择一个位置 -->
  <div class="location-map">
    <vxe-modal
      v-model="open"
      width="80%"
      height="96%"
      title="设置目标清扫点位置"
      :show-zoom="true"
      resize
      :lock-view="false"
      :mask="true"
      :position="{ top: '2%', left: '10%' }"
      transfer
      @close="open = false"
      @hide="open = false"
    >
      <div class="content">
        <div class="map">
          <div class="search">
            <a-input-search v-model="searchText" placeholder="请输入地址查找位置" enter-button @search="handleLocationSearch" />
          </div>
          <baidu-map class="map-page" :center="center" :zoom="zoom" @ready="initMap" :continuous-zoom="true" :scroll-wheel-zoom="true">
            <bm-scale anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :offset="{ width: 135, height: 15 }" />
            <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :offset="{ width: 45, height: 15 }" />
            <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" type="BMAP_NAVIGATION_CONTROL_ZOOM" show-zoom-info="true" />
          </baidu-map>
        </div>

        <div class="search-result">
          <div class="city">当前城市：{{ mapInfo.cityName }}</div>

          <div class="search-result-info">
            {{ resultInfo }}
          </div>
          <div class="address-list" v-if="addressList.length > 0" id="addressList">
            <div class="address-list-item" :class="[temp.iwOpenIndex === index ? 'hover' : '']" v-for="(item, index) in addressList" :key="index" @click="handleToPoint(item)">
              <div class="row">
                <span class="col1" :class="['mk-' + index]"> </span>
                <div class="col2">
                  <div class="address-title">{{ item.title }}</div>
                  <div class="address">{{ item.address }}</div>
                  <div class="point">坐标：{{ item.point.lng.toFixed(4) + ',' + item.point.lat.toFixed(4) }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="city-list" v-if="cityList.length > 0">
            <div class="city-list-item" @click="searchInthisCity(item.city)" v-for="(item, index) in cityList" :key="index">{{ item.city }}({{ item.num }})</div>
          </div>
          <a-pagination
            v-if="addressList.length > 0"
            class="ant-table-pagination"
            :simple="true"
            :current="pageNum"
            :total="total"
            :page-size="pageSize"
            :showTotal="(total) => `共 ${total} 条`"
            @change="changeSize"
          />
        </div>
      </div>

      <div class="map-bottom">
        <div style="display: flex; flex: 1">
          <div class="lng-lat-input"><span>经度：</span><a-input @change="handleLngLatInputChange" v-model="currentLocation.lng" allowClear></a-input></div>
          <div class="lng-lat-input"><span>纬度：</span><a-input @change="handleLngLatInputChange" v-model="currentLocation.lat" allowClear></a-input></div>
          <div class="lng-lat-input"><a-button @click="handleChangeToBd">GPS坐标转百度坐标</a-button></div>
          <div class="lng-lat-input">
            <span>地址：</span> <span style="margin-left: -13px">{{ currentAddress }}</span>
          </div>
        </div>
        <div>
          <a-button @click="open = false" class="cancel">取消</a-button>
          <a-button @click="handleSave" type="primary">保存</a-button>
        </div>
      </div>
    </vxe-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import mapBaseWithLocation from '@/views/monitor/monitorComponents/mapCommon/mapBaseWithLocation'
import { GetMapAddress } from '@/views/monitor/monitorComponents/utils/mapUtil'
import { List } from 'ant-design-vue'
import coordinateTransform from '@/utils/coordinateTransform'
export default {
  mixins: [mapBaseWithLocation],
  components: {
    AList: List,
    AListItem: List.Item
  },
  props: {
    position: {
      type: Object,
      default: () => {}
    }
  },
  watch: {},
  computed: {
    ...mapGetters(['userTenant'])
  },
  data() {
    return {
      open: false,
      center: { lng: 0, lat: 0 },
      currentLocation: {
        lng: 0,
        lat: 0
      }
    }
  },
  methods: {
    handleSave() {
      if (!this.currentLocation.lng || !this.currentLocation.lat) {
        this.$warningEx('请选择目标洁点位！')
        return
      }
      // 百度坐标转为84坐标
      const lnglat = coordinateTransform.bd09towgs84(this.currentLocation.lng, this.currentLocation.lat)
      this.$emit('onLocation', {
        lng: lnglat[0],
        lat: lnglat[1]
      })
      this.open = false
    },
    initMap(MapObj) {
      this.isAddMarker = false
      this.destZoom = 19
      this.isAddPickupMarker = true
      this.BMap = MapObj.BMap
      this.map = MapObj.map
      const myGeo = new this.BMap.Geocoder()
      this.map.setDefaultCursor('default')
      this.map.setDraggingCursor('default')
      this.center.lng = this.userTenant.lng
      this.center.lat = this.userTenant.lat
      this.zoom = 21
      const center = new this.BMap.Point(this.userTenant.lng, this.userTenant.lat)
      this.projection = new this.BMap.MercatorProjection()
      this.mapInfo.centerPoint = center
      this.map.addEventListener('load', (b) => {
        this.getCurrentCityName()
      })
      this.map.addEventListener('moveend', (b) => {
        this.getCurrentCityName()
      })
      this.map.addEventListener('dragend', (b) => {
        this.getCurrentCityName()
      })
      this.map.addEventListener('zoomend', (b) => {
        this.getCurrentCityName()
      })
      this.map.addEventListener('click', (c) => {
        this.setInputPoint(c.point)
        this.addPickupMarker(c.point)
        this.setPickupLabel()
        // 添加标识
        GetMapAddress(myGeo, this.BMap, c.point.lng, c.point.lat, (address) => {
          this.currentAddress = address
        })
      })
      this.initLocalSearch()
    },
    setPickupLabel() {
      this.pickupLabel = new this.BMap.Label('垃圾清洁点位置', { offset: new this.BMap.Size(-25, 35) })
      this.pickupOverlay.setLabel(this.pickupLabel)
    },
    handleUpdate(lng, lat) {
      console.log('handleUpdate')
      // 84坐标转bd坐标
      this.initDrawMap()
      this.open = true
      this.initLocation(lng, lat)
    },
    initLocation(lng, lat) {
      if (!this.BMap) {
        setTimeout(() => {
          this.initLocation(lng, lat)
        }, 300)
      } else {
        if (lng > 0 && lat > 0) {
          const lnglat = coordinateTransform.wgs84togcj02tobd09(lng, lat)
          lng = lnglat.lng
          lat = lnglat.lat
          this.currentLocation.lng = lng
          this.currentLocation.lat = lat
          if (lng > 0 && lat > 0) {
            const point = new this.BMap.Point(lng, lat)
            this.setLocationOnMap(point)
          }
        }
      }
    },
    handleLngLatInputChange(e) {
      // 手工输入认为是84坐标需要转换为百度坐标
      const point = new this.BMap.Point(this.currentLocation.lng, this.currentLocation.lat)
      this.setLocationOnMap(point)
    },
    // 设置当前坐标点在地图上的位置
    // 包括设置中心位置
    setLocationOnMap(point) {
      this.addPickupMarker(point)
      this.setPickupLabel()
      setTimeout(() => {
        this.map.panTo(point)
      }, 500)
    },
    // 手工输入时切换到百度坐标
    handleChangeToBd() {
      console.log('handleChangeToBd')
      const lnglat = coordinateTransform.wgs84togcj02tobd09(this.currentLocation.lng, this.currentLocation.lat)
      this.currentLocation.lng = lnglat.lng
      this.currentLocation.lat = lnglat.lat
      this.map.panTo(new this.BMap.Point(lnglat.lng, lnglat.lat))
    }
  }
}
</script>

<style lang="less" scoped>
.map-page {
  height: 100% !important;
}
.map-bottom {
  height: 7% !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  .cancel {
    margin-right: 8px;
  }
  .lng-lat-input {
    display: flex;
    // justify-content: space-between;
    align-items: center;
    margin-right: 10px;
    span:first-child {
      width: 60px;
    }
  }
}
.search {
  width: 300px;
  height: auto !important;
  position: absolute;
  z-index: 100;
  left: 20px;
  top: 15px;
  .ant-select {
    width: 100%;
  }
  .city,
  .address {
    z-index: 101;
    background-color: #fff;
    height: 300px;
    overflow: auto;
  }
}

.content {
  height: 92% !important;
  display: flex;
  .search-result {
    flex: 0 0 200px;
    .city {
      padding: 0 10px;
      color: #00c;
    }
    .search-result-info {
      padding: 0 10px;
    }
    .address-list {
      padding: 10px;
      height: 86%;
      overflow-y: auto;
      font-size: 12px;
      .address-list-item {
        cursor: pointer;
        padding-top: 2px;
        padding-bottom: 2px;
        .address-title {
          color: #00c;
        }
        border-bottom: 1px solid #ececec;

        .row {
          position: relative;
          display: flex;
          .col2 {
            flex: 1;
            padding: 2px 10px 2px 3px;
            margin-left: 22px;
          }
          span {
            cursor: pointer;
            display: block;
            width: 19px;
            height: 25px;
            background: url(/images/markers.png) no-repeat 0 0;
            position: absolute;
            top: 3px;
            left: 0;
          }
          .mk-0 {
            background-position: -23px 0;
          }
          .mk-1 {
            background-position: -23px -25px;
          }
          .mk-2 {
            background-position: -23px -50px;
          }
          .mk-3 {
            background-position: -23px -75px;
          }
          .mk-4 {
            background-position: -23px -100px;
          }
          .mk-5 {
            background-position: -23px -125px;
          }
          .mk-6 {
            background-position: -23px -150px;
          }
          .mk-7 {
            background-position: -23px -175px;
          }
          .mk-8 {
            background-position: -23px -200px;
          }
          .mk-9 {
            background-position: -23px -225px;
          }
        }
        &.hover {
          background: #f0f0f0;
        }
      }
    }
    .city-list {
      padding: 10px;
      height: 92%;
      overflow-y: auto;
      .city-list-item {
        cursor: pointer;
        color: #00c;
        padding-top: 5px;
        padding-bottom: 5px;
        border-bottom: 1px solid #ececec;
      }
    }
  }
  .map {
    flex: 1;
  }
}
</style>
